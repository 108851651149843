import React, { useMemo, useState, useCallback, useEffect } from 'react'
import { Box, useToast, Text } from '@chakra-ui/react'
import { Button } from '@blueprinthq/joy'
import { useSearchParams, Link, useNavigate } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { useFlags } from 'flagsmith/react'
import { datadogLogs } from '@datadog/browser-logs'

import { 
  useUserControllerV1GetAssignedAssessments,
  useUserControllerV1PostSubmitAssessments,
  getUserControllerV1GetAssignedAssessmentsQueryKey,
  useAssessmentControllerV1GetAssessments
} from '~/api/public'
import {
  SubmitAssessmentsDto
} from '~/api/public/models'
import { useAuthStore, usePublicUser, useOnNavBack, useIsFromMagicLink } from '@core/hooks'
import { Loading } from '@components'
import { AssessmentFormCarousel } from '@features'
import { AssessmentFormContext, AssignedAssessmentFlowStatus } from '@types'
import { useAppBarStore } from '@core/store'
import { FlagsmithFeature } from '@types'

import { ClientInviteStep } from '../public/accept-invite/accept-invite.view'

import {
  CompletedForm,
  NoAssignedAssessments,
  NoOverdueAssessments
} from './components'

export const UserAssessmentsView = () => {
  const { user } = usePublicUser()

  if (user) {
    datadogLogs.setUser({ id: user.id, type: user.type }) 
  }
  
  const appBarStore = useAppBarStore()
  const authStore = useAuthStore()

  const toast = useToast()

  const [isClientTakeNow, setClientTakeNow] = useState(false)

  const queryClient = useQueryClient()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const flags = useFlags([FlagsmithFeature.ACCEPT_INVITE_FLOW, FlagsmithFeature.ASSESSMENT_BIRTHDAY_CONFIRMATION])

  const shouldPromptAccountCreation = flags.accept_invite_flow.enabled && user.info.shouldPromptAccountCreation

  const isClinicianTakeNow = useMemo(() => {
    if (searchParams.has('context')) {
      return searchParams.get('context') === AssessmentFormContext.CLINICIAN_APP
    }
  }, [searchParams])

  /*
   * ----- QUERIES -----
   */
  const {
    mutate: submitAssessments,
    isSuccess: isSubmitAssessmentSuccess,
    isLoading: isSubmitAssessmentLoading,
  } = useUserControllerV1PostSubmitAssessments({
    mutation: {
      onSuccess() {
        queryClient.invalidateQueries(
          getUserControllerV1GetAssignedAssessmentsQueryKey(user.id)
        )
      }
    }
  })

  const { data: assignedAssessments = [], isLoading: isLoadingAssessments } =
    useUserControllerV1GetAssignedAssessments(user.id, {
      query: {
        enabled: !!user
      }
    })

  /*
   * ----- COMPONENT -----
   */
  const overdueAssignedAssessments = useMemo(() => {
    return assignedAssessments.filter(aa => aa.isOverdue)
  }, [assignedAssessments])

  const assignedAssessmentsToTake = useMemo(() => {
    return isClientTakeNow ? assignedAssessments : overdueAssignedAssessments
  }, [assignedAssessments, isClientTakeNow])

  const assessmentIds: string[] = useMemo(() => {
    if (searchParams.has('assessments')) {
      return searchParams.get('assessments')?.split(',') as string[]
    }
    return assignedAssessmentsToTake.map((a) => a.assessment.id)
  }, [searchParams, assignedAssessmentsToTake])

  const { data: assessments = [], isLoading: isAssessmentLoading } = useAssessmentControllerV1GetAssessments({
    Ids: assessmentIds,
  }, {
    query: {
      enabled: assessmentIds.length > 0,
    }
  })

  const onAllSubmitted = useCallback(
    async (formAnswers: SubmitAssessmentsDto[]) => {
      datadogLogs.logger.info('_onSubmitForm', { userId: user.id })
      const source = searchParams.has('context')
        ? (searchParams.get('context') as string)
        : AssessmentFormContext.DEFAULT

      await submitAssessments({
        userId: user.id,
        data: {
          assessments: formAnswers,
          assigneeUserId: user.id,
          isNewPatient: false,
          source,
          submissionType: (isClinicianTakeNow || isClientTakeNow) ? 'take_now' : 'default'
        }
      })

      setClientTakeNow(false)

      if (searchParams.has('more')) {
        const nextLink = searchParams.get('more')
        if (nextLink) {
          navigate(nextLink)
        }
      }
    },
    [isClientTakeNow, user.id, searchParams, user.relatedPatientId, isClinicianTakeNow, submitAssessments]
  )

  const homescreenUrl = useMemo(() => {
    return searchParams.get('homescreenUrl') || '/'
  }, [searchParams.get('homescreenUrl')])

  const isFromMagicLink = useIsFromMagicLink()

  /*
   * ----- DETERMINE CONTENT STATUS -----
   */
  const status = useMemo(() => {
    if (isLoadingAssessments || isSubmitAssessmentLoading || isAssessmentLoading)
      return AssignedAssessmentFlowStatus.LOADING
    else if (isSubmitAssessmentSuccess)
      return AssignedAssessmentFlowStatus.SUBMITTED
    else if ((assignedAssessmentsToTake.length > 0 || (isClinicianTakeNow || isClientTakeNow)) && assessments?.length > 0)
      return AssignedAssessmentFlowStatus.ASSIGNED_TAKEABLE
    else if (assignedAssessmentsToTake.length === 0)
      return AssignedAssessmentFlowStatus.ASSIGNED_NO_TAKEABLE
    return AssignedAssessmentFlowStatus.NONE_ASSIGNED
  }, [
    isLoadingAssessments,
    isSubmitAssessmentLoading,
    isAssessmentLoading,
    isSubmitAssessmentSuccess,
    assignedAssessmentsToTake,
    assessments,
    isClinicianTakeNow,
    isClientTakeNow
  ])

  const renderContent = () => {
    const shouldHideButton = isFromMagicLink || isClinicianTakeNow || !shouldPromptAccountCreation
    const commonContent = shouldHideButton ? null : (
      <Box mt="small">
        <Button variant="link" as={Link} to={homescreenUrl}>
          Back to home screen
        </Button>
      </Box>
    )

    const gotoAccountButton = isFromMagicLink && shouldPromptAccountCreation && (
        <Box mt="small">
          <Button
            variant="link"
            as={Link}
            to={authStore.isAuthenticated ? '/' : '/login'}
            state={{
              email: undefined
            }}
          >
            Log in to your account
          </Button>
        </Box>
      )

    const closeWindowPrompt = ((isFromMagicLink &&
      !shouldPromptAccountCreation) || isClinicianTakeNow) && (
        <Text mt={2}>You can close this window.</Text>
      )

    switch (status) {
      case AssignedAssessmentFlowStatus.LOADING:
        return <Loading isFullPage />
      case AssignedAssessmentFlowStatus.SUBMITTED:
        return (
          <CompletedForm text="Assessments complete!">
            <>
              {commonContent}
              {gotoAccountButton}
              {closeWindowPrompt}
            </>
          </CompletedForm>
        )
      case AssignedAssessmentFlowStatus.ASSIGNED_NO_TAKEABLE:
        return (
          <NoOverdueAssessments onTakeAnyway={() => setClientTakeNow(true)}>
            <>
              {commonContent}
              {gotoAccountButton}
            </>
          </NoOverdueAssessments>
        )
      case AssignedAssessmentFlowStatus.ASSIGNED_TAKEABLE:
        return (
          <AssessmentFormCarousel
            firstName={user.info.firstName}
            userId={user.id}
            assessments={assessments}
            onAllFormsSubmitted={onAllSubmitted}
          />
        )
      default:
        return <NoAssignedAssessments>{commonContent}</NoAssignedAssessments>
    }
  }

  /*
   * ----  SIDE EFFECTS -----
   */

  // Set 'Restart' button in app-bar when in KIOSK context
  useEffect(() => {
    appBarStore.setOnBack(null)

    const ctx = searchParams.get('context')

    if (ctx === AssessmentFormContext.KIOSK) {
      appBarStore.setLeftComponent(
        <Button
          variant="ghost"
          color="white"
          m="0px"
          onClick={() => navigate(homescreenUrl)}
        >
          Restart
        </Button>
      )
    } else {
      appBarStore.setLeftComponent(null)
    }
  }, [homescreenUrl, searchParams.get('context')])

  // Magic link, encourage user to signup/login flow
  useEffect(() => {
    if (
      shouldPromptAccountCreation &&
      isFromMagicLink &&
      status === AssignedAssessmentFlowStatus.SUBMITTED &&
      user.type === 'patient'
    ) {
      toast({
        status: 'success',
        description: 'Assessments completed!'
      })

      if (user.info.hasOnboarded) {
        if (authStore.isAuthenticated) {
          navigate('/')
        } else {
          navigate('/login', {
            state: {
              email: undefined
            }
          })
        }
      } else {
        navigate('/accept-invite', {
          state: {
            step: ClientInviteStep.SETUP_ACCOUNT,
            email: undefined,
            patientId: user.relatedPatientId,
          }
        })
      }
    }
  }, [
    isFromMagicLink,
    status,
    user,
    authStore.isAuthenticated,
    shouldPromptAccountCreation
  ])


  useOnNavBack((isFromMagicLink || isClinicianTakeNow)? null : () => {
    navigate(-1)
  })


  return (
    <Box w="100%">{renderContent()}</Box>
  )
}
