import React from 'react'
import {
  Box,
  Flex,
  Text,
  Link as HrefLink
} from '@chakra-ui/react'
import {
  ArrowRightIcon,
  AccountIcon,
  NotificationFilledIcon,
  HeartFilledIcon,
  SecurityFilledIcon,
  InfoFilledIcon,
  NewTabIcon,
  EmailFilledIcon
} from '@blueprinthq/joy'
import { Link } from 'react-router-dom'
import { useWebViewStore } from '@hooks'
import { AppContainer } from '@components'
import { usePatientProfileStore } from '@core/store'

export const SettingsView = () => {
  const webviewStore = useWebViewStore()
  const {
    activeProfile
  } = usePatientProfileStore()

  const onClickPrivacyTermsReactNative = () => {
    if (webviewStore.isReactNativeWebView) {
      webviewStore.postMessage('@action/open-privacy-terms')
    }
  }

  const onClickToSReactNative = () => {
    if (webviewStore.isReactNativeWebView) {
      webviewStore.postMessage('@action/open-tos')
    }
  }

  const privacyTermsComponent = (
    <Box
      borderTopWidth="1px"
      _first={{ borderTopWidth: 0 }}
      onClick={onClickPrivacyTermsReactNative}
    >
      <Box p={4} pr={2} display="flex" alignItems="center" gap="4">
        <Box p={2} bgColor="primary" borderRadius={8}>
          <SecurityFilledIcon fill="white" />
        </Box>
        <Box width="100%">Privacy Policy</Box>
        <Box>
          <NewTabIcon size="md" />
        </Box>
      </Box>
    </Box>
  )

  const tosComponent = (
    <Box
      borderTopWidth="1px"
      borderColor="pale_blue"
      onClick={onClickToSReactNative}
    >
      <Box p={4} pr={2} display="flex" alignItems="center" gap="4">
        <Box p={2} bgColor="primary" borderRadius={8}>
          <InfoFilledIcon fill="white" />
        </Box>
        <Box width="100%">Terms of Service</Box>
        <Box>
          <NewTabIcon size="md" />
        </Box>
      </Box>
    </Box>
  )

  return (
    <AppContainer title="Settings">
      <Box mb={4}>
        <Text fontWeight="bold">Profile</Text>
      </Box>
      <Box mb={4} rounded="lg" bg="white">
        <Link to="/settings/account">
          <Box borderTopWidth="1px" _first={{ borderTopWidth: 0 }}>
            <Box p={4} pr={2} display="flex" alignItems="center" gap="4">
              <Box p={2} bgColor="primary" borderRadius={8}>
                <AccountIcon fill="white" />
              </Box>
              <Box width="100%">Account</Box>
              <Box>
                <ArrowRightIcon size="md" />
              </Box>
            </Box>
          </Box>
        </Link>
        <Link to="/settings/notifications">
          <Box borderTopWidth="1px" borderColor="pale_blue">
            <Box p={4} pr={2} display="flex" alignItems="center" gap="4">
              <Box p={2} bgColor="primary" borderRadius={8}>
                <NotificationFilledIcon fill="white" />
              </Box>
              <Box width="100%">{'Notifications'}</Box>
              <Box>
                <ArrowRightIcon size="md" />
              </Box>
            </Box>
          </Box>
        </Link>
      </Box>
      {webviewStore.platform !== 'android' && (
        <>
          <Box mb={4}>
            <Text fontWeight="bold">Connect</Text>
          </Box>
          <Box mb={4} rounded="lg" bg="white">
            <Box
              borderTopWidth="1px"
              _first={{ borderTopWidth: 0 }}
              borderColor="pale_blue"
            >
              <Box
                onClick={() => webviewStore.postMessage('@action/health-app')}
                p={4}
                pr={2}
                display="flex"
                justifyItems="space-between"
                alignItems="center"
                gap="4"
              >
                <Flex align="center" width="100%">
                  <Box p={2} bgColor="primary" borderRadius={8} mr="small">
                    <HeartFilledIcon fill="white" />
                  </Box>
                  <Box>
                    <Text>Apple Health</Text>
                  </Box>
                </Flex>
                <Box width="120px" float="right" whiteSpace="nowrap">
                  {webviewStore.isReactNativeWebView ? (
                    <Box float="right">
                      <ArrowRightIcon size="md" />
                    </Box>
                  ) : (
                    <HrefLink
                      href={process.env.REACT_APP_BRANCH_APP_INVITE_LINK}
                      color="primary"
                    >
                      Get the app
                    </HrefLink>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      )}
      <Box mb={4}>
        <Text fontWeight="bold">Crisis Resources</Text>
      </Box>
      <Box mb={4} rounded="lg" bg="white">
        <Link to={`../user/${activeProfile.userId}/safety-net`}>
          <Box borderTopWidth="1px" _first={{ borderTopWidth: 0 }}>
            <Box p={4} pr={2} display="flex" alignItems="center" gap="4">
              <Box p={2} bgColor="primary" borderRadius={8}>
                <InfoFilledIcon fill="white" />
              </Box>
              <Box width="100%">Your Safety Net</Box>
              <Box>
                <ArrowRightIcon size="md" />
              </Box>
            </Box>
          </Box>
        </Link>
      </Box>
      <Box mb={4}>
        <Text fontWeight="bold">Technical Support</Text>
      </Box>
      <Box mb={4} rounded="lg" bg="white">
        <a href="mailto:clients@blueprint-health.com">
          <Box borderTopWidth="1px" borderColor="pale_blue">
            <Box p={4} pr={2} display="flex" alignItems="center" gap="4">
              <Box p={2} bgColor="primary" borderRadius={8}>
                <EmailFilledIcon fill="white" />
              </Box>
              <Box width="100%">Contact Blueprint</Box>
              <Box>
                <ArrowRightIcon size="md" />
              </Box>
            </Box>
          </Box>
        </a>
      </Box>
      <Box mb={4}>
        <Text fontWeight="bold">Privacy & Terms</Text>
      </Box>
      <Box mb="large" rounded="lg" bg="white">
        {webviewStore.isReactNativeWebView ? (
          privacyTermsComponent
        ) : (
          <a href="https://www.blueprint-health.com/privacy" target="_blank">
            {privacyTermsComponent}
          </a>
        )}
        {webviewStore.isReactNativeWebView ? (
          tosComponent
        ) : (
          <a href="https://www.blueprint-health.com/terms" target="_blank">
            {tosComponent}
          </a>
        )}
      </Box>
    </AppContainer>
  )
}
