import React, { useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useSearchParams } from 'react-router-dom'
import { useFlags } from 'flagsmith/react'
import { Container, GridItem, Box } from '@chakra-ui/react'
import { LayoutGrid, theme } from '@blueprinthq/joy'

import { 
  useUserControllerV1GetConsentInfo,
  useUserControllerV1AcceptConsent,
  useUserControllerV1GetBirthdayVerificationStatus, 
  getUserControllerV1GetConsentInfoQueryKey,
} from '~/api/public'
import { UserDto, VerifyBirthdayDtoTelecomType } from '~/api/public/models'

import { Loading } from '@components'
import { AssessmentFormContext, FlagsmithFeature } from '@types'

import { BirthdayVerification } from './components/birthday-verification'
import { ConsentInfo } from './components/consent-info'

interface PublicUserVerificationProps {
  user: UserDto
  children: React.ReactNode
}

export const PublicUserVerification = ({ user, children }: PublicUserVerificationProps) => {
  const queryClient = useQueryClient()
  const [searchParams] = useSearchParams()
  const flags = useFlags([FlagsmithFeature.ACCEPT_INVITE_FLOW, FlagsmithFeature.ASSESSMENT_BIRTHDAY_CONFIRMATION])

  const source = searchParams.get('context') as string
  const sourceToTelecomType: { [key: string]: VerifyBirthdayDtoTelecomType } = {
    manual_email: 'email',
    email: 'email',
    manual_sms: 'phone',
    sms: 'phone',
  }
  const telecomType = sourceToTelecomType[source]

  const isClinicianTakeNow = useMemo(() => {
    if (searchParams.has('context')) {
      return searchParams.get('context') === AssessmentFormContext.CLINICIAN_APP
    }
  }, [searchParams])


  const { data: consentInfo, isLoading: isLoadingConsent } =
  useUserControllerV1GetConsentInfo(user.id, {
    query: {
      enabled: !!user
    }
  })

  const { mutate: acceptConsent } = useUserControllerV1AcceptConsent({
    mutation: {
      onSuccess() {
        queryClient.invalidateQueries(
          getUserControllerV1GetConsentInfoQueryKey(user.id)
        )
        document.documentElement.scrollTo({
          top: 0,
          left: 0,
          // @ts-ignore
          behavior: 'instant'
        })
      }
    }
  })

  const { data, isLoading: isLoadingBirthdayVerificationStatus } = useUserControllerV1GetBirthdayVerificationStatus(user.id, { telecomType }, {
    query: {
      enabled: !!user && !!telecomType && !isClinicianTakeNow && flags.assessment_birthday_confirmation.enabled
    }
  })

  const needsBirthdayVerification = data?.isVerified as boolean | undefined === false


  const onAcceptConsent = async () => {
    await acceptConsent({
      userId: user.id
    })
  }

  const renderContent = () => {
    const commonContent = null
    if (isLoadingBirthdayVerificationStatus || isLoadingConsent) {
      return <Loading isFullPage />
    } else if (!isClinicianTakeNow && consentInfo?.consentRequired) {
      return (
        <ConsentInfo
          onAcceptConsent={onAcceptConsent}
          consentCopy={consentInfo?.consentCopy || ''}
        >
          {commonContent}
        </ConsentInfo>
      )
    } else if (!isClinicianTakeNow && flags.assessment_birthday_confirmation.enabled && needsBirthdayVerification) {
      return (
        <BirthdayVerification 
          userId={user.id}
          firstName={String(user?.relatedPatientFirstName)} 
          userType={user.type}
          telecomType={telecomType}
        />
      )
    }
    return (
      <>
        {children}
      </>
    )
  }

  return (
    <Container
      height={{
        // @ts-ignore
        base: `calc(100vh - ${theme.components.AppBar.baseStyle.height.base})`,
        // @ts-ignore
        sm: `calc(100vh - ${theme.components.AppBar.baseStyle.height.sm})`,
        // @ts-ignore
        xl: `calc(100vh - ${theme.components.AppBar.baseStyle.height.xl})`
      }}
      paddingLeft={{
        base: '8px',
        sm: '0px'
      }}
      paddingRight={{
        base: '8px',
        sm: '0px'
      }}
    >
      <LayoutGrid>
        <GridItem
          colStart={{
            base: 1,
            sm: 2,
            md: 4
          }}
          colEnd={{
            base: 5,
            sm: 8,
            md: 10
          }}
        >
          <Box height="100%" pt="medium">
            {renderContent()}
          </Box>
        </GridItem>
      </LayoutGrid>
    </Container>
  )

  
}