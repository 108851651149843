import React from 'react'
import { Flex, Box, Text } from '@chakra-ui/react'
import { CheckIcon, Button } from '@blueprinthq/joy'

interface NoOverdueAssessmentsProps {
  onTakeAnyway: () => void
  children?: JSX.Element | null
}

export const NoOverdueAssessments = ({
  onTakeAnyway,
  children
}: NoOverdueAssessmentsProps) => {
  return (
    <Flex align="center" direction="column" paddingTop="small">
      <Flex
        bg="primary"
        width="64px"
        height="64px"
        borderRadius="50%"
        align="center"
        justify="center"
      >
        <CheckIcon size="lg" fill="white" />
      </Flex>
      <Box mt="large">
        <Text textAlign="center" fontWeight="bold" fontSize="lg">
          You're all set!
        </Text>
      </Box>
      <Box mt="xsmall" textAlign="center">
        <Text>
          You've already completed the assessment(s) in the timeframe requested
          by your clinician, so we don't need anything more at this time. We
          appreciate you!
        </Text>
      </Box>
      <Box mt="large">
        <Button variant="link" onClick={onTakeAnyway}>
          Take assessment anyway
        </Button>
      </Box>
      {children}
    </Flex>
  )
}
