import React from 'react'
import {
  Box,
  Text,
  VStack,
  Button,
  useTheme
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { DateTime } from 'luxon'
import { useOnNavBack, useWebViewStore } from '@hooks'
import { TextField, InfoIcon } from '@blueprinthq/joy'
import { formatPhone } from '@utilities'
import { useNavigate } from 'react-router-dom'
import { WebViewMessages } from '@types'
import { AppContainer } from '@components'
import { usePatientProfileStore } from '@core/store'

interface ReadOnlyInputProps {
  label: string
  value: string
}

const ReadOnlyInput = ({ label, value }: ReadOnlyInputProps) => {
  const theme = useTheme()
  return (
    <TextField
      label={label}
      value={value}
      disabled
      _disabled={{
        color: theme.colors.black,
        'WebkitTextFillColor': theme.colors.black
      }}
    />
  )
}

export const AccountView = () => {
  const { activeProfile } = usePatientProfileStore()
  
  const navigate = useNavigate()
  const webviewStore = useWebViewStore()
  const theme = useTheme()

  useOnNavBack(() => {
    if (webviewStore.isReactNativeWebView && webviewStore.isUsingOldNav()) {
      webviewStore.postMessage(WebViewMessages.NAVIGATE_SETTINGS)
    } else {
      navigate('/settings')
    }
  })

  return (
    <AppContainer title="Account">
      <Box
        mb={8}
        display="flex"
        alignItems="flex-start"
        bg="pale_gray"
        py={3}
        px={3}
        borderRadius="4px"
      >
        <Box>
          <InfoIcon />
        </Box>
        <Box ml={2}>
          <Text>
            Reach out to your clinician to update your personal information.
          </Text>
        </Box>
      </Box>
      <VStack spacing="medium">
        <ReadOnlyInput label="First Name" value={activeProfile?.firstName} />
        <ReadOnlyInput label="Last Name" value={activeProfile?.lastName} />
        <ReadOnlyInput
          label="Date of Birth"
          value={DateTime.fromISO(activeProfile?.dateOfBirth)
            .setZone('utc')
            .toFormat('LL/dd/yyyy')}
        />
        <ReadOnlyInput label="Email" value={activeProfile?.email || ''} />
        <ReadOnlyInput
          label="Phone"
          value={formatPhone(activeProfile?.phoneNumber || '')}
        />
        <Button
          as={Link}
          to="/logout"
          size="lg"
          isFullWidth
          bg={theme.colors.black}
        >
          Sign Out
        </Button>
      </VStack>
    </AppContainer>
  )
}
