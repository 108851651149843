import React, { useRef, useEffect } from 'react'
import { Textarea, useTheme } from '@chakra-ui/react'

interface Props {
  value: string
  onChange?: (value: string) => void
  isDisabled?: boolean
  autoFocus?: boolean
}

export const FreeText = ({
  value,
  onChange,
  isDisabled,
  autoFocus = false
}: Props) => {
  const theme = useTheme()
  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = '0px'
      const scrollHeight = textAreaRef.current.scrollHeight
      textAreaRef.current.style.height = scrollHeight + 'px'
    }
  }, [textAreaRef, value])

  const handleChange = (e: any) => {
    if (onChange) {
      onChange(e.target.value)
    }
  }

  return (
    <Textarea
      autoFocus={autoFocus}
      disabled={isDisabled}
      value={value}
      ref={textAreaRef}
      onChange={handleChange}
      sx={{
        minHeight: 150,
        maxHeight: '30vh'
      }}
      _disabled={{
        color: theme.colors.black,
        'WebkitTextFillColor': theme.colors.black
      }}
    />
  )
}
