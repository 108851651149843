import React, { forwardRef } from 'react'
import {
  Box,
  Text,
  Stack,
  Divider,
  FormControl,
  RadioGroup,
  FormErrorMessage,
  Radio,
  Textarea
} from '@chakra-ui/react'
import { FastField, FastFieldProps } from 'formik';
import {
  CheckInContentDtoV2NodesItem,
  CheckInFreetextNode,
  CheckInLikertNode,
  CheckInMultipleChoiceNode,
  CheckInHeadingNode,
  CheckInLikertQuestionAnswer
} from '~/api/client/models'
import { isMobileOnly } from 'react-device-detect'
import { Checkbox } from '@blueprinthq/joy';
import { useScrollToInvalidField } from '@core/hooks'

interface CheckInFormFieldProps {
  node: CheckInContentDtoV2NodesItem
  number: number
}

const NonmemoizedCheckInFormField = ({ node, number }: CheckInFormFieldProps) => {
  const ref = useScrollToInvalidField<HTMLDivElement>(node.id)

  const renderField = () => {
    if (node.type === 'heading') {
      return (
        <CheckInHeadingField node={node} />
      )
    } else if (node.type === 'multiple_choice' && (node as CheckInMultipleChoiceNode).multiselect) {
      return (
        <CheckInMultipleChoiceField
          ref={ref}
          node={node as CheckInMultipleChoiceNode}
        />
      )
    } else if (node.type === 'free_text') {
      return <CheckInFreetextField ref={ref} node={node as CheckInFreetextNode} />
    }
    else {
      return (
        <CheckInRadioField
          ref={ref}
          node={node as CheckInLikertNode}
        />
      )
    }
  }

  return (
    <Box key={node.id} w="100%">
      {node.type !== 'heading' && <Text fontWeight="bold" mb={'small'}>
        {number}. {node.label}
      </Text>}
      {renderField()}
    </Box>
  )
}

export const CheckInFormField = React.memo(
  NonmemoizedCheckInFormField,
  (prevProps, nextProps) => {
    return prevProps.node.id === nextProps.node.id
  }
)

export const CheckInHeadingField = ({ node }: { node: CheckInHeadingNode }) => {
  return (
    <Box>
      <Text fontSize="lg" mb={3}>
        {node.label}
      </Text>
    </Box>
  )
}

export const CheckInRadioField = forwardRef<HTMLDivElement, { node: CheckInLikertNode | CheckInMultipleChoiceNode } >(({ node}, ref) => {
  return (
    <FastField name={node.id}>
      {({ field, form }: FastFieldProps) => {
        return (
          <FormControl
            isInvalid={
              form.errors[field.name] !== undefined &&
              form.touched[field.name] === true
            }
          >
            <RadioGroup
              ref={ref}
              {...field}
              onChange={value => {
                form.setFieldValue(field.name, value)
              }}
            >
              <Stack 
                direction={'column'} 
                spacing="0px"
                border="1px solid"
                borderColor="pale_gray"
                borderRadius="4px"
                divider={<Divider />}
              >
                {node.answers.map((option, i) => (
                  <Box
                    key={i}
                    w="100%"
                    _hover={{
                      bg: !isMobileOnly ? 'blue.50' : 'initial'
                    }}
                  >
                    <Radio p="xsmall" value={option.id} w="100%">
                      {option.label.toString()}
                    </Radio>
                  </Box>
                ))}
              </Stack>
            </RadioGroup>
            <FormErrorMessage>
              {form.errors !== undefined && form.errors[field.name]?.toString()}
            </FormErrorMessage>
          </FormControl>
        )
      }}
    </FastField>
  )
})

export const CheckInFreetextField = forwardRef<
HTMLDivElement,
{ node: CheckInFreetextNode }
>(({ node }, ref) => {
  return (
    <FastField name={node.id}>
      {({ field, form }: FastFieldProps) => {
        return (
          <FormControl
            isInvalid={
              form.errors[field.name] !== undefined &&
              form.touched[field.name] === true
            }
          >
            <Box ref={ref}>
              <Textarea {...field} height="148px" />
            </Box>
            <FormErrorMessage>
              {form.errors !== undefined && form.errors[field.name]?.toString()}
            </FormErrorMessage>
          </FormControl>
        )
      }}
    </FastField>
  )
})

export const CheckInMultipleChoiceField = forwardRef<
HTMLDivElement,
{ node: CheckInMultipleChoiceNode }
>(({ node }, ref) => {
  return (
    <FastField name={node.id} type="checkbox">
      {({ field, form }: FastFieldProps) => {
        // Form field structure gets updated sometimes before new values change
        if (!(field.name in form.values)) {
          return null
        }
        return (
          <FormControl
            isInvalid={
              form.errors[field.name] !== undefined &&
              form.touched[field.name] !== undefined
            }
          >
            <Stack 
              direction="column"
              ref={ref}
              spacing="0px"
              border="1px solid"
              borderColor="pale_gray"
              borderRadius="4px"
              divider={<Divider />}
            >
              {node.answers.map((option, i) => (
                <Box
                  key={i}
                  w="100%"
                  _hover={{
                    bg: !isMobileOnly ? 'blue.50' : 'initial'
                  }}
                >
                  <Checkbox
                    {...field}
                    p="xsmall"
                    value={option.id}
                    w="100%"
                    isChecked={form.values[field.name].includes(option.id)}
                    onChange={e => {
                      let values = form.values[field.name]

                      if (values.includes(e.target.value)) {
                        values = values.filter(
                          (val: string) => val !== e.target.value
                        )
                      } else {
                        values = [...values, e.target.value]
                      }

                      form.setFieldValue(field.name, values)
                    }}
                  >
                    {option.label}
                  </Checkbox>
                </Box>
              ))}
            </Stack>
            <FormErrorMessage>
              {form.errors !== undefined && form.errors[field.name]?.toString()}
            </FormErrorMessage>
          </FormControl>
        )
      }}
    </FastField>
  )
})