import React from 'react'
import { Box, Text, Radio, RadioGroup, Stack, Divider } from '@chakra-ui/react'
import { isMobileOnly } from 'react-device-detect'
import { CheckInLikertQuestionAnswer } from '~/api/client/models'

interface Props {
  label: string
  options: CheckInLikertQuestionAnswer[]
  value: string
  onChange?: (value: string) => void
  isDisabled?: boolean
}

export const LikertScale = ({
  label,
  options,
  value,
  onChange,
  isDisabled
}: Props) => {
  return (
    <Box>
      <Text fontWeight="bold" mb={4} mt={6}>
        {label}
      </Text>
      <RadioGroup value={value} onChange={e => (onChange ? onChange(e) : null)}>
        <Stack
          direction="column"
          spacing="0px"
          border="1px solid"
          borderColor="pale_gray"
          borderRadius="4px"
          divider={<Divider />}
        >
          {options.map((option, idx) => (
            <Box
              key={idx}
              w="100%"
              _hover={{
                bg: !isMobileOnly && !isDisabled ? 'blue.50' : 'initial'
              }}
            >
              <Radio
                p="xsmall"
                value={option.value?.toString()}
                w="100%"
                disabled={isDisabled}
              >
                {option.label}
              </Radio>
            </Box>
          ))}
        </Stack>
      </RadioGroup>
    </Box>
  )
}
