import React from 'react'
import {
  Container,
  Box,
  Text,
  GridItem,
  Heading,
  VStack,
  Button,
  Center,
  Stack,
  Divider
} from '@chakra-ui/react'
import { LayoutGrid, ArrowLeftIcon, ArrowRightIcon } from '@blueprinthq/joy'
import { StickyFooter } from '@components'
import { useParams, Link, useNavigate } from 'react-router-dom'
import { useOnNavBack } from '@hooks'
import { keyBy, findIndex } from 'lodash'
import { useUsersControllerV2GetCheckInScores } from '~/api/client'
import {
  CheckInLikertNode,
  CheckInHeadingNode,
  CheckInFreetextNode,
  CheckInMultipleChoiceNode,
  CheckInContentDtoV2NodesItem
} from '~/api/client/models'
import { BinaryTable, FreeText, LikertScale } from './components'
import { DateTime } from 'luxon'
import { usePatientProfileStore } from '@core/store'

export const CompletedCheckInView = () => {
  const { checkInId, checkInScoreId } = useParams()
  const { activeProfile } = usePatientProfileStore()

  const navigate = useNavigate()
  useOnNavBack(() => navigate(`/check-ins/${checkInId}/completed`))

  const { data } = useUsersControllerV2GetCheckInScores(activeProfile.userId, {
    checkInIds: checkInId ? [checkInId] : []
  }, { query: { enabled: Boolean(checkInId) }})

  if (!data) return null

  const { checkInModules, checkInScores } = data

  const checkInScore = checkInScores.find(score => score.id === checkInScoreId)
  const checkInModule = checkInModules.find(m => m.versionId === checkInScore?.versionId)

  const currentIndex = findIndex(checkInScores, s => s.id === checkInScore?.id)
  const nextScore = checkInScores[currentIndex + 1]
  const prevScore = checkInScores[currentIndex - 1]
  const answers = checkInScore ? keyBy(checkInScore.answers, 'key') : {}

  const renderHeadingNode = (node: CheckInHeadingNode) => {
    return (
      <Box>
        <Text fontSize="lg" mb={3}>
          {node.label}
        </Text>
      </Box>
    )
  }

  const renderLikertContent = (node: CheckInLikertNode) => {
    const value = answers[node.id]?.value || ''

    return (
      <Box>
        <LikertScale
          key={node.id}
          label={node.label}
          options={node.answers}
          value={value}
          isDisabled
        />
      </Box>
    )
  }

  const renderFreeTextContent = (node: CheckInFreetextNode) => {
    const value = answers[node.id]?.value || ''

    return (
      <Box>
        <Text mb={3}>{node.label}</Text>
        <FreeText
          value={value}
          isDisabled
        />
      </Box>
    )
  }

  const renderMultipleChoiceContent = (node: CheckInMultipleChoiceNode) => {
    return (
      <Box>
        <Text mb={3}>{node.label}</Text>
        <Stack
          direction="column"
          spacing="0px"
          border="1px solid"
          borderColor="pale_gray"
          borderRadius="4px"
          divider={<Divider />}
        >
        {node.answers.map((answer) => {
          const value: string | string[] = answers[node.id]?.value || (node.multiselect ? [] : '')
          return (
            <Box>
              <BinaryTable
                multiselect={node.multiselect}
                label={answer.label}
                // @ts-ignore
                isChecked={node.multiselect ? value.some((v) => v === answer.id) : value === answer.id}
                isDisabled
              />
            </Box>
          )
        })}
        </Stack>
      </Box>
    )
  }

  const renderContent = (content: CheckInContentDtoV2NodesItem) => {
    switch (content.type) {
      case 'likert':
        return renderLikertContent(content as CheckInLikertNode)
      case 'free_text':
        return renderFreeTextContent(content as CheckInFreetextNode)
      case 'multiple_choice':
        return renderMultipleChoiceContent(content as CheckInMultipleChoiceNode)
      case 'heading':
        return renderHeadingNode(content as CheckInHeadingNode)
      default:
        return null
    }
  }

  if (!checkInModule) return null

  return (
    <Container
      paddingTop={{
        base: '24px'
      }}
      paddingLeft={{
        base: '8px',
        sm: '0px'
      }}
      paddingRight={{
        base: '8px',
        sm: '0px'
      }}
      pb="100px"
    >
      <LayoutGrid>
        <GridItem
          colStart={{
            base: 1,
            sm: 2,
            md: 4
          }}
          colEnd={{
            base: 5,
            sm: 8,
            md: 10
          }}
        >
          <Box w="100%">
            {checkInModule.type === 'treatment_activity' && (
              <Heading size="lg" mb={4}>
                {checkInModule.title}
              </Heading>
            )}
            <Box
              mb={6}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Button
                as={Link}
                to={
                  prevScore
                    ? `/check-ins/${checkInModule.id}/completed/${prevScore.id}`
                    : '#'
                }
                disabled={!prevScore}
                variant="unstyled"
                borderRadius="50%"
                size="md"
                borderWidth="1px"
                borderColor="pale_gray"
              >
                <Center w="100%" h="100%">
                  <ArrowLeftIcon size="md" />
                </Center>
              </Button>
              <Box>
                {checkInScore
                  ? DateTime.fromISO(checkInScore.createdAt).toFormat(
                      'ccc L/dd/yy h:mm a'
                    )
                  : null}
              </Box>
              <Button
                as={Link}
                to={
                  nextScore
                    ? `/check-ins/${checkInModule.id}/completed/${nextScore.id}`
                    : '#'
                }
                disabled={!nextScore}
                variant="unstyled"
                borderRadius="50%"
                size="md"
                borderWidth="1px"
                borderColor="pale_gray"
              >
                <Center w="100%" h="100%">
                  <ArrowRightIcon />
                </Center>
              </Button>
            </Box>
            <VStack spacing="large">
              {checkInModule.content.nodes.map((content, idx) => (
                <Box key={idx} w="100%">
                  {renderContent(content)}
                </Box>
              ))}
            </VStack>
          </Box>
          <StickyFooter>
            <Button as={Link} to={`/check-ins/${checkInId}`} size="lg" w="100%">
              Check-in
            </Button>
          </StickyFooter>
        </GridItem>
      </LayoutGrid>
    </Container>
  )
}
