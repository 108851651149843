import React from 'react'
import {
  Box,
  Text,
  Flex,
  HStack,
  Switch,
  VStack
} from '@chakra-ui/react'
import { useWebViewStore } from '@hooks'
import { formatPhone } from '@utilities'

import { Field, FieldProps } from 'formik'
import { usePatientProfileStore } from '@core/store'

interface NotificationSectionProps {
  title: string,
  subtitle: string | React.ReactElement,
  activeOptionalComms: any,
  messageType: string,
  switchesEnabled: boolean
  children?: any
}

export const NotificationSection = ({
  title,
  subtitle,
  activeOptionalComms,
  messageType,
  switchesEnabled,
  children
}: NotificationSectionProps) => {
  const webviewStore = useWebViewStore()
  const { activeProfile } = usePatientProfileStore()

  const resubscribePhoneNumber = formatPhone(process.env.REACT_APP_TWILIO_PHONE_NUMBER)

  return (
    <Box mb={6} bg="white" rounded="lg">
      <Box p={4}>
        <Text fontWeight="bold">{title}</Text>
        {typeof subtitle === 'string' ? <Text>{subtitle}</Text> : subtitle}
      </Box>
      {switchesEnabled && <Box>
        <VStack px={4} pb={4} pt={2}>
          <Flex w="100%" justify="space-between">
            <Text>Push</Text>
            <HStack spacing="small">
              {(!webviewStore.isReactNativeWebView && <Text color="medium_gray">Managed in app</Text>) ||
              (!activeOptionalComms.isPushSubscribed && <Text color="medium_gray">Unsubscribed</Text>)}
              <Field
                name={`${messageType}_push`} size="lg"
              >
                {({form, field}: FieldProps) => (
                  <Switch
                    {...field}
                    size="lg"
                    isDisabled={!webviewStore.isReactNativeWebView || !activeOptionalComms.isPushSubscribed}
                    onChange={e => {
                      form.setFieldValue(`${messageType}_push`, !JSON.parse(e.currentTarget.value))
                      form.submitForm()
                    }}
                    isChecked={form.values[`${messageType}_push`]}
                  />
                )}
              </Field>
            </HStack>
          </Flex>
          {webviewStore.isReactNativeWebView && !activeOptionalComms.isPushSubscribed && <Text pt={1} w="100%">
            Ensure you have Blueprint notifications enabled in your phone's settings.
          </Text>}
        </VStack>
        <VStack px={4}>
          <Flex w="100%" py={4} borderTopWidth="0.5px" justify="space-between">
            <Text>Email</Text>
            <HStack spacing="small">
              {!activeOptionalComms.isEmailSubscribed && <Text color="medium_gray">Unsubscribed</Text>}
              <Field
                name={`${messageType}_email`} size="lg"
              >
                {({ form, field }: FieldProps) => (
                  <Switch
                    {...field}
                    size="lg"
                    onChange={e => {
                      form.setFieldValue(`${messageType}_email`, !JSON.parse(e.currentTarget.value))
                      form.submitForm()
                    }}
                    isChecked={form.values[`${messageType}_email`]}
                  />
                )}
              </Field>
            </HStack>
          </Flex>
        </VStack>
        <VStack px={4}>
          <Flex
            pt={4}
            pb={!activeOptionalComms.isSmsSubscribed || !Boolean(activeProfile?.phoneNumber) ? 0 : 4}
            w="100%" borderTopWidth="0.5px"
            justify="space-between"
          >
            <Text>Text</Text>
            <HStack spacing="small">
              {(!Boolean(activeProfile?.phoneNumber) && <Text color="medium_gray">No phone on file</Text>) ||
              (!activeOptionalComms.isSmsSubscribed && <Text color="medium_gray">Unsubscribed</Text>)}
              <Field
                name={`${messageType}_sms`} size="lg"
              >
                {({ form, field }: FieldProps) => (
                  <Switch
                    {...field}
                    size="lg"
                    isDisabled={!activeOptionalComms.isSmsSubscribed}
                    onChange={e => {
                      form.setFieldValue(`${messageType}_sms`, !JSON.parse(e.currentTarget.value))
                      form.submitForm()
                    }}
                    isChecked={form.values[`${messageType}_sms`]}
                  />
                )}
              </Field>
            </HStack>
          </Flex>
          {!Boolean(activeProfile?.phoneNumber) && <Text pb={4} pt={1} w="100%">
            To add a phone number, contact your clinician.
          </Text> ||
          !activeOptionalComms.isSmsSubscribed && <Text pb={4} pt={1} w="100%">
            {`To resubscribe, text “START” to ${resubscribePhoneNumber}.`}
          </Text>}
        </VStack>
      </Box>}
      {children}
    </Box>
  )
}