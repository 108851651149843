import React, { useEffect, useState, useMemo } from 'react'
import { AppBar, AuthWall, BottomNavView } from '@features'
import { Outlet, useLocation } from 'react-router-dom'
import { useAccountControllerV1GetUserProfiles } from '~/api/client'
import { Box, useTheme } from '@chakra-ui/react'
import { datadogLogs } from '@datadog/browser-logs'
import { observer } from 'mobx-react-lite'

import { Loading } from '@components'
import { useWebViewStore } from '@hooks'
import { PatientProfileStoreProvider, useAppBarStore, usePatientProfileStore } from '@core/store'

import { theme } from '@blueprinthq/joy'
import { AppPrompt } from '@features'
import { useFlagsmith } from 'flagsmith/react'

const AuthedUserLayoutContent = observer(() => {
  const flagsmith = useFlagsmith()
  const { data: profile, isLoading: isProfilesLoading, isError } = useAccountControllerV1GetUserProfiles({
    query: {
      select: (profiles) => {
        // Defaulting to first profile for now until EoC work is supported for multiple profiles
        return profiles[0]
      }
    }
  })
  const webViewStore = useWebViewStore()
  const appBarStore = useAppBarStore()

  const profileStore = usePatientProfileStore()

  const platform = webViewStore.isReactNativeWebView
    // @ts-ignore
    ? window.reactNativePlatform
    : 'web'

  useEffect(() => {
    if (profile) {
      profileStore.setActiveProfile(profile)
      appBarStore.setLogoUrl(profile.clinicLogoUrl)

      flagsmith.identify(profile.userId, {
        clinicId: profile.clinicId,
        patientId: profile.patientId
      })
      datadogLogs.setUser({ id: profile.userId, type: profile.userType })
      datadogLogs.logger.info('Monitoring authed user.')
    }

  }, [profile])

  const isLoading: boolean = useMemo(() => Boolean(isProfilesLoading || (profile?.clinicLogoUrl && !appBarStore.logoUrl)), [isProfilesLoading, profile?.clinicLogoUrl, appBarStore.logoUrl])

  return (
    <>
      <AppBar isLoading={isLoading} />
      <Box
        height={{
          // @ts-ignore
          base: `calc(100vh - ${theme.components.AppBar.baseStyle.height.base})`,
          // @ts-ignore
          sm: `calc(100vh - ${theme.components.AppBar.baseStyle.height.sm})`,
          // @ts-ignore
          xl: `calc(100vh - ${theme.components.AppBar.baseStyle.height.xl})`
        }}
      >
        {isLoading ? <Loading isFullPage /> :
          isError ? (
            <Box p="small">
              An error occured. Please try closing and reopening the app.
            </Box>
          ) : (
            <Outlet />
          )}
      </Box>
    </>
  )
})

const primaryNavRoutes = ['/', '/progress', '/settings']
const fullBackgroundRoutes = primaryNavRoutes.concat(['/settings/notifications'])

export const AuthedUserLayout = () => {
  const [isNavMenuVisible, setIsNavMenuVisible] = useState<boolean>(false)

  const theme = useTheme()
  const webViewStore = useWebViewStore()
  const location = useLocation()
  const appBarStore = useAppBarStore()

  const isPrimaryNavRoute = primaryNavRoutes.includes(location.pathname)
  const isFullBackgroundRoute = fullBackgroundRoutes.includes(location.pathname)

  useEffect(() => {
    if ((webViewStore.isReactNativeWebView ? !webViewStore.isUsingOldNav() : true) && isPrimaryNavRoute) {
      setIsNavMenuVisible(true)
    } else {
      setIsNavMenuVisible(false)
    }
  }, [isPrimaryNavRoute])

  useEffect(() => {
    if (isFullBackgroundRoute) {
      document.body.style.backgroundColor = theme.colors.pale_blue
    } else {
      document.body.style.backgroundColor = theme.colors.white
    }

    return () => {
      document.body.style.backgroundColor = theme.colors.white
    }
  }, [isFullBackgroundRoute])

  useEffect(() => {
    if (!isPrimaryNavRoute) {
      appBarStore.toggleCheckInVisibility(false)
    } else {
      appBarStore.toggleCheckInVisibility(true)
    }

    return () => {
      appBarStore.toggleCheckInVisibility(false)
    }
  }, [isPrimaryNavRoute])

  return (
    <AuthWall unauthedRedirect="/login">
      <PatientProfileStoreProvider>
        <AuthedUserLayoutContent />
        {isNavMenuVisible && <BottomNavView /> }
        {(isNavMenuVisible && !webViewStore.isReactNativeWebView) &&  <AppPrompt /> }
      </PatientProfileStoreProvider>
    </AuthWall>
  )
}
