import React from 'react'
import { Box, Stack, Divider, Radio } from '@chakra-ui/react'
import { Checkbox } from '@blueprinthq/joy'
import { isMobileOnly } from 'react-device-detect'

interface Props {
  label: string
  isChecked: boolean
  onChange?: (val: boolean) => void
  isDisabled?: boolean
  multiselect?: boolean
}

export const BinaryTable = ({
  label,
  multiselect,
  isChecked,
  onChange,
  isDisabled
}: Props) => {
  return (
    <Box
      _hover={{ bg: isMobileOnly || isDisabled ? 'initial' : 'blue.50' }}
    >
      {!multiselect ? (
        <Radio 
          p="xsmall"
          w="100%"
          isChecked={isChecked}
          onChange={e => (onChange ? onChange(e.target.checked) : null)}
          disabled={isDisabled}
        >{label}</Radio>
      ) : (
        <Checkbox
          p="xsmall"
          w="100%"
          isChecked={isChecked}
          onChange={e => (onChange ? onChange(e.target.checked) : null)}
          disabled={isDisabled}
        >
          {label}
        </Checkbox>
      )}
    </Box>
  )
}
