import React from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { Text } from '@chakra-ui/react'

import { AppContainer } from '@components'
import { ResourceCards } from './resource-cards'
import { useUserControllerV1GetSafetyNet } from '~/api/public'
import { useOnNavBack } from '@hooks'
import { Loading } from '@components'

export type Resource = {
  type: string
  displayText: string
  value: string
}

export const SafetyNetView = () => {
  const navigate = useNavigate()
  const location = useLocation()

  // we don't want to show the back button if they've navigated to this page from a text/email link
  const hasHistory = location.key !== 'default'

  useOnNavBack(hasHistory ? () => navigate(-1): null)
  const { userId } = useParams()

  const {
    data: resources,
    isLoading
  }: { data: Resource[] | undefined; isLoading: boolean } =
    useUserControllerV1GetSafetyNet(userId as string)

  if (isLoading) return <Loading isFullPage />

  return (
    <>
      <AppContainer title="Your Safety Net">
        <Text mb="small">
          If you are thinking about suicide or would like immediate emotional
          support, please use one of the crisis resources below.
        </Text>
        <ResourceCards resources={resources} />
        <Text mb="small">
          If your issue is an emergency, call 911 or go to your nearest
          emergency room.
        </Text>
        <Text>
          Blueprint does not offer crisis counceling or emergency services and
          does not notify your clinician in real-time.
        </Text>
      </AppContainer>
    </>
  )
}
